import ColumnWidthType from "@prop-types/ColumnWidthType";
import HelmetProps from "@prop-types/HelmetProps";
import ImageLoadingType from "@prop-types/ImageLoadingType";
import ImageOrientationProps, {
  MEDIA_ORIENTATION
} from "@prop-types/ImageOrientationProps";
import JSXProps from "@prop-types/JSXProps";
import MediaProps from "@prop-types/MediaProps";
import { MediaBS } from "@style-variables";
import { shallowDeepCompare } from "@utils/array";
import { getMinBreakpoint } from "@utils/breakpoints";
import { toHelmetJSX } from "@utils/functions";
import PreloadHelper from "@utils/preload";
import { escapeReact } from "@utils/react";
import { filterObjectProps, getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ChildRefAwareComponent from "./ChildRefAwareComponent";
import MediaEmbeder from "./MediaEmbeder";
import Picture from "./Picture";
import { withPlaceholder } from "./Placeholder";

export default class Media extends ChildRefAwareComponent {
  static ORIENTATION_LEFT = "left";
  static ORIENTATION_RIGHT = "right";
  static ORIENTATION_TOP = "top";
  static ORIENTATION_BOTTOM = "bottom";

  constructor(props) {
    super(props);

    this.helper = new PreloadHelper();
    this.helper.setProps(this.getPreloadProps());
    this.helper.mountLinks();
  }

  componentDidUpdate(prevProps) {
    if (shallowDeepCompare(prevProps, this.props)) {
      this.helper.setProps(this.getPreloadProps());
      this.helper.mountLinks();
    }
  }

  getImgProps() {
    let imgProps = {
      title: this.props.title,
      loading: this.props.loading,
      placeholder: this.props.placeholder
    };

    if (typeof this.props.img === "string") {
      imgProps.src = this.props.img;
    }
    if (typeof this.props.img === "object") {
      imgProps = { ...imgProps, ...this.props.img };
    }

    return imgProps;
  }

  getPreloadProps() {
    return {
      ...this.props,
      items: this.props.img.embededVideo ? [] : [this.props],
      preloadCount: +this.props.preload,
      prefetchCount: +this.props.prefetch
    };
  }

  shouldRenderImage() {
    const imgProps = this.getImgProps();

    return Boolean(imgProps.url || imgProps.src);
  }

  renderImageCol(wrapper) {
    const imgProps = this.getImgProps();

    if (!this.shouldRenderImage()) {
      return null;
    }

    const minWidth = getMinBreakpoint(imgProps.sizes);
    const minHeight = minWidth * (imgProps.aspect || 0);

    const style = {
      //minWidth: minWidth + "px",
      minHeight: minHeight + "px"
    };

    const content = this.props.img.embededVideo ? (
      <MediaEmbeder
        {...{ ...imgProps, title: null, text: null }}
        style={style}
      />
    ) : (
      <Picture {...imgProps} style={style} />
    );

    const className = getComponentClassName(
      MediaBS,
      "image",
      ["d-flex align-items-center", this.props.mediaImageClassName]
        .filter(Boolean)
        .join(" ")
    );

    const Wrapper =
      wrapper || (props => <Col md={this.props.md || 6} {...props} />);

    return (
      <Wrapper className={className} key={0}>
        {this.props.ribbons}
        {content}
      </Wrapper>
    );
  }

  renderBodyTitle(title, key, titleAs) {
    const Title = props => React.createElement(titleAs || this.props.as, props);

    return title ? (
      <Title
        className={getComponentClassName(MediaBS, "title")}
        key={`title-${key}`}
      >
        {withPlaceholder(this.props.placeholder, title)}
      </Title>
    ) : null;
  }

  renderBodyText(text, key) {
    return text ? (
      <div
        className={getComponentClassName(MediaBS, "text")}
        key={`text-${key}`}
      >
        {withPlaceholder(this.props.placeholder, escapeReact(text), {
          className: "w-75",
          lines: 5
        })}
      </div>
    ) : null;
  }

  renderItems() {
    return this.props.items
      ? this.props.items
          .map((item, i) => [
            this.renderBodyTitle(item.title, i, item.titleAs),
            this.renderBodyText(item.text, i)
          ])
          .flat()
          .filter(Boolean)
      : null;
  }

  renderBodyCol(wrapper) {
    const title = this.renderBodyTitle(this.props.title);

    const text = this.renderBodyText(this.props.text);

    const items = this.renderItems();

    const Wrapper =
      wrapper ||
      (props => (
        <Col
          md={12 - (this.shouldRenderImage() ? this.props.md || 6 : 0)}
          xs="12"
          {...props}
        />
      ));

    return (
      <Wrapper className={getComponentClassName(MediaBS, "body")} key={1}>
        {title}
        {text}
        {items}
      </Wrapper>
    );
  }

  renderByOrientation(orientation) {
    const wrapper = props => (
      <div
        {...{
          ...props,
          className: [props.className, "wrap-around"].filter(Boolean).join(" ")
        }}
      />
    );

    switch (orientation) {
      case MEDIA_ORIENTATION.top:
        return (
          <React.Fragment>
            <Row>{this.renderImageCol()}</Row>
            <Row>{this.renderBodyCol()}</Row>
          </React.Fragment>
        );
      case MEDIA_ORIENTATION.bottom:
        return (
          <React.Fragment>
            <Row>{this.renderBodyCol()}</Row>
            <Row>{this.renderImageCol()}</Row>
          </React.Fragment>
        );
      case MEDIA_ORIENTATION.right:
        return <Row>{[this.renderBodyCol(), this.renderImageCol()]}</Row>;
      case MEDIA_ORIENTATION.wrapAround:
        return (
          <Row>
            <Col>
              {[
                this.renderImageCol(props =>
                  wrapper({
                    ...props,
                    style: {
                      ...props.style,
                      width: (100 * (this.props.md || 6)) / 12 + "%"
                    }
                  })
                ),
                this.renderBodyCol(wrapper)
              ]}
            </Col>
          </Row>
        );
      default:
        return <Row>{[this.renderImageCol(), this.renderBodyCol()]}</Row>;
    }
  }

  render() {
    const header = this.props.header ? (
      <Row as={this.props.headerAs || "h1"}>
        <Col>{withPlaceholder(this.props.placeholder, this.props.header)}</Col>
      </Row>
    ) : null;

    const helmet = this.props.helmet ? (
      <Helmet prioritizeSeoTags>{toHelmetJSX(this.props.helmet)}</Helmet>
    ) : null;

    const body = this.props.disabled
      ? null
      : this.renderByOrientation(this.props.imageOrientation);

    if (!header && !body) {
      return helmet;
    }

    const dataset = filterObjectProps(this.props, "data-");

    return (
      <Container
        fluid
        id={this.props.id}
        className={getComponentClassName(MediaBS, null, this.props.className)}
        {...dataset}
      >
        {/* HELMET */}
        {helmet}
        {/* TITLE */}
        {header}
        {body}
      </Container>
    );
  }
}

Media.propTypes = {
  ...ChildRefAwareComponent.propTypes,
  id: PropTypes.string,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  md: ColumnWidthType(), // image col width
  header: PropTypes.string,
  headerAs: JSXProps(),
  ...MediaProps(),
  imageOrientation: ImageOrientationProps(),
  className: PropTypes.string,
  mediaImageClassName: PropTypes.string,
  as: JSXProps(),
  helmet: PropTypes.shape(HelmetProps()),
  disabled: PropTypes.bool,
  loading: ImageLoadingType(),
  prefetch: PropTypes.bool,
  preload: PropTypes.bool,
  placeholder: PropTypes.bool,
  ribbons: PropTypes.arrayOf(PropTypes.element)
};

Media.defaultProps = {
  imageOrientation: MEDIA_ORIENTATION.left,
  as: "h5",
  md: 6, // default image col width
  headerAs: "h1",
  prefetch: false,
  preload: false
};
