import RouteLinkImage from "@components-core/RouteLinkImage";
import { carouselOptimalDelayFactor } from "@components-utils";
import {
  POSITION_FIXED_BOTTOM,
  POSITION_FIXED_TOP,
  POSITION_RELATIVE_BODY,
  POSITION_RELATIVE_TOP
} from "@constants";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { MarqueeWidgetBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Carousel } from "react-bootstrap";
import MediaQuery from "react-responsive";
import PureComponent from "./PureComponent";

/**
 * @description Renders a static/carousel banner of linkified brands-like icons/images
 * @param {Object} props - The component properties
 * @returns Returns the JSX element
 */
export default class MarqueeWidget extends PureComponent {
  constructor(props) {
    super(props);

    this.columnFilter = this.columnFilter.bind(this);
    this.carouselFilter = this.carouselFilter.bind(this);
    this.getBreakpointWidth = this.getBreakpointWidth.bind(this);

    this.maxcols = 12; //max Bootstrap responsive columns count

    this.state = { interval: props.intervalDelay ? null : props.interval };

    this.intervalDelayTimer = null;
  }

  componentDidMount() {
    if (this.props.intervalDelay && null !== this.props.interval) {
      this.intervalDelayTimer = setTimeout(
        () =>
          this.setState({
            interval: this.props.interval
          }),
        carouselOptimalDelayFactor() * this.props.intervalDelay
      );
    }
  }

  componentWillUnmount() {
    if (this.intervalDelayTimer) {
      clearTimeout(this.intervalDelayTimer);
    }
  }

  /**
   * @description Get the optimal max/min width for MediaQuery wrapper
   * @returns The max breakpoint width
   * @memberof MarqueeWidget
   */
  getBreakpointWidth() {
    const breakpoints = Object.keys(mediaBreakpoint.size).map(
      key => mediaBreakpoint.size[key]
    );

    let maxWidth = breakpoints.find(
      width => this.props.items.length * 250 <= width
    );

    return maxWidth ? maxWidth : mediaBreakpoint.size.xxl;
  }

  /**
   * @description Span the items by columns (used by default for desktop-like devices)
   * @param {*} items
   * @returns {array} Returns the items wrapped within a responsive column
   * @memberof MarqueeWidget
   */
  columnFilter(items) {
    return items.map((item, key) => (
      <div key={key} className={getComponentClassName(MarqueeWidgetBS, "item")}>
        {item}
      </div>
    ));
  }

  /**
   * @description Wraps the items within a carousel slides (used by default for mobile-like devices)
   * @param {*} items
   * @returns {array} Returns the items wrapped within a responsive carousel slide
   * @memberof MarqueeWidget
   */
  carouselFilter(items) {
    return items.map((item, key) => {
      return (
        <Carousel.Item
          key={key}
          className={getComponentClassName(MarqueeWidgetBS, "carousel-item")}
        >
          {item}
        </Carousel.Item>
      );
    });
  }

  renderMobileDevice(items) {
    return (
      <Carousel
        controls={false}
        indicators={false}
        keyboard={false}
        interval={this.state.interval}
        className={getComponentClassName(MarqueeWidgetBS, "carousel")}
      >
        {new RouteLinkImage({
          items,
          filters: [this.carouselFilter]
        }).getItems()}
      </Carousel>
    );
  }

  renderDesktopDevice(items) {
    return <RouteLinkImage items={items} filters={[this.columnFilter]} />;
  }

  render() {
    if (!this.props.enabled) {
      return null;
    }

    const maxWidth = this.getBreakpointWidth();

    return (
      <div
        className={getComponentClassName(
          MarqueeWidgetBS,
          null,
          [this.props.className, "justify-content-" + this.props.justifyContent]
            .filter(Boolean)
            .join(" ")
        )}
        style={this.props.style}
      >
        <MediaQuery minWidth={maxWidth}>
          {matches => {
            if (matches) {
              return this.renderDesktopDevice(this.props.items);
            }

            return this.renderMobileDevice(this.props.items);
          }}
        </MediaQuery>
      </div>
    );
  }
}

MarqueeWidget.propTypes = {
  ...ItemsAwareProps,
  interval: PropTypes.number,
  intervalDelay: PropTypes.number,
  enabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  justifyContent: PropTypes.oneOf([
    "start",
    "end",
    "center",
    "between",
    "around"
  ]),
  position: PropTypes.oneOf([
    POSITION_RELATIVE_TOP,
    POSITION_RELATIVE_BODY,
    POSITION_FIXED_BOTTOM,
    POSITION_FIXED_TOP
  ])
};

MarqueeWidget.defaultProps = {
  enabled: true,
  justifyContent: "center",
  intervalDelay: 3000,
  interval: 4000
};
