import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import SiteLogo from "@components/SiteLogo/SiteLogo";
import {
  EVENT_SEARCH_TOGGLED,
  PAGE_HASH_USER_EDIT_PASSWORD,
  PAGE_HASH_USER_EDIT_PROFILE,
  PAGE_HASH_USER_ORDER_HISTORY,
  PAGE_KEY_LOGOUT,
  PAGE_KEY_USER_PROFILE
} from "@constants";
import MenuBarHeaderProps from "@prop-types/MenuBarHeaderProps";
import { MenuBarHeaderBS } from "@style-variables";
import { mediaBreakpoint } from "@utils/breakpoints";
import { createCustomEvent } from "@utils/dom";
import { getComponentClassName } from "@utils/strings";
import React from "react";
import { Navbar } from "react-bootstrap";
import MediaQuery from "react-responsive";
import MenuBarButton from "./Button";
import MenuBarCartButton from "./MenuBarCartButton";
import MenuBarFavoriteButton from "./MenuBarFavoriteButton";
import MenuBarSearchButton from "./MenuBarSearchButton";
import MenuBarToggle from "./Toggle";

/**
 * @description Menu header component
 * @export
 * @class MenuBarHeader
 * @extends {PureComponent}
 */
class MenuBarHeader extends PureComponent {
  /**
   * @description Render the menu toggle element
   * @returns {JSX}
   * @memberof MenuBarHeader
   */
  renderMenuToggler() {
    if (!this.props.navbarToggle) {
      return null;
    }

    const navbarToggle = (
      <MenuBarToggle {...this.props.navbarToggle} inline={true} />
    );

    if (this.props.navbarToggle.position === "left") {
      return navbarToggle;
    }

    return navbarToggle;
  }

  /**
   * @description Render the brand element
   * @param {Boolean} [mobile=false] Whether the component is rendered for mobile devices
   * @returns {JSX}
   * @memberof MenuBarHeader
   */
  renderBrand(mobile = false) {
    return (
      <SiteLogo
        isMobile={mobile}
        className={MenuBarHeaderBS}
        wrapper={Navbar.Brand}
      />
    );
  }

  /**
   * @description Enhances the given menu bar user button props
   * @param {Object} item A menubar header user button props
   * @returns {Object} Returns the enhanced menubar header user button props, NULL to skip rendering
   * @memberof MenuBarHeader
   */
  getUserButtonProps(item) {
    const loginStatus = this.props.loginStatus;
    const props = {
      ...item,
      icon: { ...item.icon },
      className: "user-button",
      showTitle: this.props.showButtonsTitle
    };

    props.icon.className = "text-muted";

    if (loginStatus.isLogged) {
      props.icon.className = "";
      props.tooltip = loginStatus.isAnonymous
        ? null
        : loginStatus.loggedUser.email.concat(
            loginStatus.loggedUser.customerNumber
              ? ` (${loginStatus.loggedUser.customerNumber})`
              : ""
          );

      const builtinLoginProvider = loginStatus.isAnonymous
        ? false
        : loginStatus.loggedUser &&
          loginStatus.loggedUser.provider === "builtin";

      // override the "/login" while logged
      props.href = this.props.pathfinder.get(PAGE_KEY_USER_PROFILE);

      if (loginStatus.isDeveloper) {
        props.icon.className = "text-danger";
        props.tooltip +=
          "\n" + this.props.i18n.TITLE_LOGGED_AS.replace("%role%", "DEV");
      } else if (loginStatus.isSuperAdmin) {
        props.icon.className = "text-success";
        props.tooltip +=
          "\n" +
          this.props.i18n.TITLE_LOGGED_AS.replace("%role%", "SuperAdmin");
      } else if (loginStatus.isAdmin) {
        props.icon.className = "text-primary";
        props.tooltip +=
          "\n" + this.props.i18n.TITLE_LOGGED_AS.replace("%role%", "Admin");
      }

      props.title =
        loginStatus.loggedUser.userName.length >
        loginStatus.loggedUser.firstName.length
          ? loginStatus.loggedUser.firstName
          : loginStatus.loggedUser.userName;

      const i18n = this.props.i18n.components.UserProfile;

      props.dropdown = [
        {
          url:
            this.props.pathfinder.get(PAGE_KEY_USER_PROFILE) +
            PAGE_HASH_USER_EDIT_PROFILE,
          title: i18n.BTN_EDIT_PROFILE
        },
        {
          url:
            this.props.pathfinder.get(PAGE_KEY_USER_PROFILE) +
            PAGE_HASH_USER_EDIT_PASSWORD,
          title: i18n.BTN_CHANGE_PASSWORD
        },
        { isSeparator: true },
        {
          url:
            this.props.pathfinder.get(PAGE_KEY_USER_PROFILE) +
            PAGE_HASH_USER_ORDER_HISTORY,
          title: i18n.BTN_ORDER_HISTORY,
          disabled: builtinLoginProvider
        },
        { isSeparator: true },
        {
          url: this.props.pathfinder.generate(PAGE_KEY_LOGOUT),
          title: i18n.BTN_LOGOUT
        }
      ];
    }

    return props;
  }

  /**
   * @description Enhances the given menu bar admin button props
   * @param {Object} item A menubar header admin button props
   * @returns {Object} Returns the enhanced menubar header admin button props, NULL to skip rendering
   * @memberof MenuBarHeader
   */
  getAdminButtonProps(item) {
    const loginStatus = this.props.loginStatus;
    const props = {
      ...item,
      icon: { ...item.icon },
      className: "admin-button",
      showTitle: this.props.showButtonsTitle
    };

    if (loginStatus.isLogged) {
    } else {
      return null;
    }

    return props;
  }

  /**
   * @description Render the menu header buttons
   * @param {Boolean} [mobile=false] When true render buttons for mobile devices
   * @returns {JSX}
   * @memberof MenuBarHeader
   */
  renderButtons(mobile = false) {
    const loginStatus = this.props.loginStatus;
    const trusted = (loginStatus.loggedUser || {}).trusted;

    const shouldRender = item => {
      let result = item.isSearchButton
        ? Boolean(this.props.searchEngine)
        : item.isFavoriteButton
        ? loginStatus.isAdmin
        : !item.isUserButton || trusted || this.props.security.enabled;

      return (
        result &&
        (!item.isAdminButton ||
          ((loginStatus.isAdmin ||
            loginStatus.isSuperAdmin ||
            loginStatus.isDeveloper) &&
            (trusted || this.props.security.adminToolbox)))
      );
    };

    const buttons = this.props.menubarHeader
      .filter(shouldRender)
      .map((item, index) => {
        const Factory = item.isCartButton
          ? MenuBarCartButton
          : item.isFavoriteButton
          ? MenuBarFavoriteButton
          : item.isSearchButton
          ? MenuBarSearchButton
          : MenuBarButton;

        let props = {
          ...item,
          icon: { ...item.icon },
          showTitle: this.props.showButtonsTitle,
          i18n: this.props.i18n
        };

        if (item.isCartButton) {
          props.icon.icon = (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 713 732"
              height="21px"
            >
              <g fill="none" stroke="currentColor" strokeWidth="20px">
                <polygon points="8.54 725 705 725 625 173 80.5 173" />
                <path d="m207 256s-11.8-248 150-248 148 249 148 249" />
              </g>
            </svg>
          );
        }
        if (item.icon.icon === "phone-volume") {
          props.icon.icon = (
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 800.79 866.44"
              height="21px"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeWidth="20px"
                d="m24.24,135.48c-.38.57-.76,1.12-1.14,1.69-6.88,10.07-89.68,144.97,183.1,452.72,197.89,223.26,297.78,254.82,321.2,259.27,5,.95,9.99,1.94,14.95,3.06,23.47,5.34,84.88,16.59,119.85-2.34,33.49-18.13,87.43-51.96,104.96-63.05,9.97-6.3,18.06-15.29,22.79-26.1,7.45-16.98,9.34-43.23-17.09-77.52-35.44-45.98-79.83-107.78-102.02-138.95-18.47-25.93-54.73-32.03-80.27-13.02-.57.42-1.14.86-1.71,1.3-31.68,24.52-63.35,48.27-76.43,58.02-7.99,5.96-17.11,10.27-26.86,12.34-12.76,2.7-28.91,2.2-40.71-11.69-22.67-26.67-185.33-214.67-185.33-214.67,0,0-21.33-34.67,2.67-58.67,20.71-20.71,71.2-68.22,84.66-80.86,3.91-3.67,7.27-7.92,9.77-12.67,6.98-13.23,13.57-38.55-15.76-65.14-36.71-33.27-124.74-115.88-148.61-138.31-7.98-7.49-17.95-12.62-28.77-14.28-13.57-2.08-31.57.25-49.29,17.92C67.58,71.06,29.81,127.09,24.24,135.48Z"
              />
            </svg>
          );
        }

        if (item.isUserButton) {
          props = this.getUserButtonProps(item);
        } else if (item.isAdminButton) {
          props = this.getAdminButtonProps(item);
        }

        if (null === props) {
          return null;
        }

        if (item.isSearchButton) {
          props.href = undefined;
          props.onClick = e =>
            window.dispatchEvent(createCustomEvent(EVENT_SEARCH_TOGGLED));
          props.searchboxDocked = this.props.searchboxDocked;
        }

        return <Factory {...props} key={index} inline={mobile} />;
      });

    let rightToggler = null;

    if (this.props.navbarToggle) {
      if (this.props.navbarToggle.position === "left") {
        if (!buttons.length) {
          return null;
        }
      } else {
        rightToggler = this.renderMenuToggler();
      }
    }

    return (
      <div
        className={getComponentClassName(
          MenuBarHeaderBS,
          "buttons",
          "d-flex align-items-center"
        )}
      >
        {buttons}
        <MediaQuery {...mediaBreakpoint.lgDown}>{rightToggler}</MediaQuery>
      </div>
    );
  }

  renderMobileDevice() {
    let leftToggler = null;

    const navbarToggle = this.renderMenuToggler();

    if (navbarToggle) {
      if (this.props.navbarToggle.position === "left") {
        leftToggler = navbarToggle;
      }
    }

    return (
      <MediaQuery {...mediaBreakpoint.mobile}>
        <Navbar collapseOnSelect variant="light">
          {this.renderBrand(true)}
          {leftToggler}
          <div className="buttons">{this.renderButtons(true)}</div>
        </Navbar>
      </MediaQuery>
    );
  }

  renderDesktopDevice() {
    return (
      <MediaQuery {...mediaBreakpoint.default}>
        <Navbar collapseOnSelect variant="light" expand expanded>
          <div className="buttons">
            {this.renderBrand()}
            {this.renderButtons()}
          </div>
        </Navbar>
      </MediaQuery>
    );
  }

  render() {
    const className = getComponentClassName(
      MenuBarHeaderBS,
      null,
      this.props.className
    );

    return (
      <div className={className}>
        {this.props.children}
        {this.renderMobileDevice()}
        {this.renderDesktopDevice()}
      </div>
    );
  }
}

MenuBarHeader.propTypes = MenuBarHeaderProps();
MenuBarHeader.defaultProps = {
  showButtonsTitle: false
};

MenuBarHeader.mapValueToProps = (value, ownProps) => ({
  menubarHeader: value.menubarHeader,
  i18n: value.i18n
});

MenuBarHeader.mapStateToProps = (state, ownProps) => null;
MenuBarHeader.mapDispatchToProps = null;

export default connectHOCs(MenuBarHeader, {
  withSite: true,
  withConnect: true
});
