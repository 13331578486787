import InfiniteScrollComponent from "@components-core/InfiniteScrollComponent";
import { connectHOCs } from "@components-utils";
import { PRODUCT_PAGE_SELECTORS } from "@constants";
import ColumnWidthType from "@prop-types/ColumnWidthType";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import { ProductCategoryBS } from "@style-variables";
import { calcImgMaxSize, getSliderImgSize } from "@utils/image";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProductCategoryItem from "./Item";

class ProductCategory extends InfiniteScrollComponent {
  renderItems() {
    const itemsPerSlide = Math.ceil(12 / this.props.colspan);

    let items = this.state.items;

    if (!this.state.items.length) {
      if (this.props.placeholder) {
        items = Array.from({ length: itemsPerSlide }, () => ({}));
      }
    }

    const imgSize =
      this.props.imgSize ||
      calcImgMaxSize(this.state.items, this.props.colspan);

    const sliderImgSize = getSliderImgSize(this.props.colspan, false);

    return items
      .map(item => {
        if (!item.img || !item.img.sizes) {
          return item;
        }

        return {
          ...item,
          img: {
            ...item.img,
            sizes: sliderImgSize
          }
        };
      })
      .map((item, index) => (
        <Col
          key={index}
          className="m-0 pl-3 pr-0 pb-3"
          lg={this.props.colspan}
          sm="12"
          md="6"
          xl="2"
        >
          <ProductCategoryItem
            className="h-100"
            key={index}
            item={item}
            impressionList={this.props.categoryId}
            selectorType={this.props.selectorType}
            imgSize={imgSize}
            placeholder={this.props.placeholder}
          />
        </Col>
      ));
  }

  render() {
    const more = this.renderMoreItemsButton();
    const items = this.renderItems();

    const error = this.props.error ? (
      <Row className="justify-content-md-center">
        {this.props.error.message}
      </Row>
    ) : null;

    return (
      <Container
        fluid
        className={getComponentClassName(
          this.props.className,
          null,
          this.props.isFetching ? "fetching" : null
        )}
      >
        {error}
        <Row className="justify-content-md-center">{items}</Row>
        {more}
      </Container>
    );
  }
}

ProductCategory.propTypes = {
  ...InfiniteScrollComponent.propTypes,
  categoryId: PropTypes.string,
  colspan: ColumnWidthType(),
  imgSize: PropTypes.shape(MaxSizeProps),
  selectorType: PropTypes.oneOf(PRODUCT_PAGE_SELECTORS),
  placeholder: PropTypes.bool
};

ProductCategory.defaultProps = {
  ...InfiniteScrollComponent.defaultProps,
  batchSize: 6,
  colspan: 4,
  className: ProductCategoryBS
};

// ------------------- REDUX ----------------------
ProductCategory.mapDispatchToProps = null;

ProductCategory.mapStateToProps = (state, ownProps) => {
  return {
    items: state.productFilterResult.items,
    isFetching: state.productFilterResult.isFetching
  };
};

export default connectHOCs(ProductCategory, {
  withSite: true,
  withConnect: true
});
