import { dangerouslySetInnerHTML } from "@utils/react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Picture from "@components-core/Picture";
import { getAsString, withPlaceholder } from "@components-core/Placeholder";
import PureComponent from "@components-core/PureComponent";
import RouteLink from "@components-core/RouteLink";
import { connectHOCs } from "@components-utils";
import CurrencyProps from "@prop-types/CurrencyProps";
import ImageProps from "@prop-types/ImageProps";
import JSXProps from "@prop-types/JSXProps";
import { ProductSerieItemBS } from "@style-variables";
import { getMinBreakpoint } from "@utils/breakpoints";
import { getSEOScoreLevel } from "@utils/functions";
import { /*formatCurrency,*/ getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

class ProductSerieItem extends PureComponent {
  renderPicture(imgProps) {
    const aspect = imgProps.aspect || 1;
    const minWidth = getMinBreakpoint(imgProps.sizes);

    const style = imgProps.style || {};

    //style.minWidth = (style.minWidth || minWidth) + "px";
    style.minHeight = (style.minHeight || minWidth * aspect) + "px";

    return (
      <Picture
        {...imgProps}
        style={style}
        className="card-img card-img-top"
        alt={imgProps.title}
        placeholder={this.props.placeholder}
      />
    );
  }

  renderText() {
    const content =
      "object" === typeof this.props.text
        ? withPlaceholder(this.props.placeholder, this.props.text)
        : null;

    const html =
      "object" === typeof this.props.text
        ? null
        : {
            __html: this.props.placeholder
              ? Array.from({ length: 2 }, getAsString).join("")
              : this.props.text
          };

    let subContent = null;

    if (this.props.subtext) {
      const subtext =
        "object" === typeof this.props.subtext
          ? withPlaceholder(this.props.placeholder, this.props.subtext)
          : null;

      const subtextHtml =
        "object" === typeof this.props.subtext
          ? null
          : {
              __html: withPlaceholder(
                this.props.placeholder,
                this.props.subtext
              )
            };

      subContent = dangerouslySetInnerHTML(subtextHtml, Card.Body, {
        children: subtext,
        className: getComponentClassName(ProductSerieItemBS, "subtext")
      });
    }

    const button = this.props.showAllProductsButton ? (
      <div className="text-center">
        <Button variant="secondary" size="sm">
          {this.props.i18n.SHOW_ALL_ITEMS}
        </Button>
      </div>
    ) : null;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col xs="11" className="px-0">
              <Card.Title
                as="h2"
                className={getComponentClassName(ProductSerieItemBS, "title")}
              >
                {withPlaceholder(this.props.placeholder, this.props.title)}
              </Card.Title>
              {/* <Card.Text
                className={getComponentClassName(ProductSerieItemBS, "price")}
              >
                {this.props.i18n.components.ProductSerie.LABEL_PRICE_FROM.replace(
                  "%price%",
                  formatCurrency(
                    this.props.price,
                    this.props.currencyPrefix,
                    this.props.currencySuffix
                  )
                )}
              </Card.Text> */}
            </Col>
            {/* <Col
              xs="1"
              className={getComponentClassName(
                ProductSerieItemBS,
                "arrow",
                "px-0 my-auto"
              )}
            >
              <FontAwesomeIcon size="3x" icon="angle-right" />
            </Col> */}
          </Row>
        </Container>
        <Card.Text
          dangerouslySetInnerHTML={html}
          className={getComponentClassName(
            ProductSerieItemBS,
            "text",
            this.props.truncateText ? "text-truncated" : null
          )}
        >
          {content}
        </Card.Text>
        {button}
        {subContent}
      </React.Fragment>
    );
  }

  render() {
    let imgProps = { title: this.props.title };

    if (typeof this.props.img === "string") {
      imgProps.src = this.props.img;
    }

    if (typeof this.props.img === "object") {
      imgProps = { ...imgProps, ...this.props.img };
    }

    const Factory = this.props.horizontal ? Card.Body : Card.Footer;

    const image = this.renderPicture(imgProps);

    const body = (
      <Factory
        className={getComponentClassName(
          ProductSerieItemBS,
          "header",
          this.props.horizontal ? "align-self-center" : null
        )}
      >
        {this.renderText()}
      </Factory>
    );

    let content = null;

    if (this.props.horizontal) {
      content = (
        <Row className="no-gutters align-items-center">
          <Col
            sm="12"
            lg="9"
            className={getComponentClassName(
              ProductSerieItemBS,
              "image-wrapper"
            )}
          >
            {image}
          </Col>
          <Col
            sm="12"
            lg="3"
            className={getComponentClassName(
              ProductSerieItemBS,
              "summary-wrapper"
            )}
          >
            {body}
          </Col>
        </Row>
      );
    } else {
      content = (
        <React.Fragment>
          {image}
          <Factory
            className={getComponentClassName(ProductSerieItemBS, "header")}
          >
            {this.renderText()}
          </Factory>
        </React.Fragment>
      );
    }

    return (
      <Container
        fluid
        className={getComponentClassName(
          ProductSerieItemBS,
          null,
          ["border px-0", getSEOScoreLevel(this.props.seo)]
            .filter(Boolean)
            .join(" ")
        )}
        id={ProductSerieItemBS + "-" + this.props.id}
      >
        <RouteLink to={this.props.url} title={this.props.title}>
          <Card.Link as="div" href={this.props.url}>
            {content}
          </Card.Link>
        </RouteLink>
      </Container>
    );
  }
}

ProductSerieItem.propTypes = {
  title: PropTypes.string.isRequired,
  titleAs: JSXProps(),
  price: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  img: PropTypes.shape(ImageProps),
  url: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  truncateText: PropTypes.bool,
  showAllProductsButton: PropTypes.bool,
  ...CurrencyProps,
  placeholder: PropTypes.bool
};

ProductSerieItem.defaultProps = {
  horizontal: false,
  truncateText: true,
  showAllProductsButton: true
};

export default connectHOCs(ProductSerieItem, { withSite: true });
