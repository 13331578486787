import ProductFavorite from "@components-core/Favorite";
import { withPlaceholder } from "@components-core/Placeholder";
import { connectHOCs } from "@components-utils";
import {
  renderRibbons1,
  shouldHideOverflow,
  transform
} from "@components/Ribbon/utils";
import {
  PRODUCT_PAGE_SELECTORS,
  PRODUCT_SELECTOR_TYPE_FAVORITE,
  RIBBON_SELECTION_ARTICLE_PAGE_ONLY
} from "@constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JSXProps from "@prop-types/JSXProps";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import ProductProps from "@prop-types/ProductProps";
import {
  compareItemAdd,
  compareItemRemove,
  compareSet
} from "@redux-actions/compare";
import { pushProductImpression } from "@redux-actions/tracking";
import { ProductCategoryItemBS } from "@style-variables";
import {
  getProductDiscount,
  getSEOScoreDesc,
  getSEOScoreLevel,
  isProductAvailable
} from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import EnergyClass from "../EnergyClass/EnergyClass";
import Availability from "../Product/Availability";
import ProductButtons from "../Product/Buttons";
import ProductPrice from "../Product/Price";
import ProductRating from "../Product/Rating";
import ProductTitle from "../Product/Title";
import { renderImageThumbnail as _renderImageThumbnail } from "./utils";

const ProductCategoryItem = props => {
  const compareProducts = props.compareProducts;

  const productItem = props.placeholder
    ? {
        ...props.item,
        buttons: props.item.buttons || { product: {} },
        img: props.item.img || { sizes: props.imgSize },
        title: props.item.title,
        subtitle: props.item.subtitle
      }
    : props.item;

  const handleCompareItemChange = (compare, id) => {
    if (compare) {
      props.compareItemAdd(id);
    } else {
      props.compareItemRemove(id);
    }
  };

  /**
   * @description Helper for getting the subcomponent classname
   * @param {string} suffix The suffix to join (by dash) to the baseclass()
   * @param {string} [otherClassName=null] When given a string to join (by space) to the resulted classname
   * @returns {String} Returns a classname derived from the main component classname
   * @memberof ProductCategoryItem
   */
  const getClassName = (suffix, otherClassName = null) =>
    getComponentClassName(ProductCategoryItemBS, suffix, otherClassName);

  const _ribbons = props.ribbons
    ? transform(props.ribbons)
        .map(ribbon => {
          if (RIBBON_SELECTION_ARTICLE_PAGE_ONLY !== ribbon.itemSelection) {
            return ribbon;
          }

          return null;
        })
        .filter(Boolean)
    : null;

  // overflow=hidden and no padding is a must for corner ribbons
  const mediaImageClassName =
    _ribbons && shouldHideOverflow(_ribbons) ? "overflow-hidden p-0" : null;

  const ribbons = renderRibbons1(_ribbons, ribbon =>
    ribbon.article_id.includes(+productItem.id)
  );

  const renderImageThumbnail = _renderImageThumbnail({
    productItem,
    tagDiscount: props.tagDiscount,
    supportsTagDiscount: props.supportsTagDiscount,
    placeholder: props.placeholder,
    imgSize: props.imgSize,
    removeBackground: true
  });

  const renderCompareItemToggle = itemIsCompared => {
    const i18n = props.i18n.components.ProductFilterActiveSet;

    const comparedItem = compareProducts.items[productItem.id];

    const compareToggleState =
      typeof compareProducts.toggleState === "undefined"
        ? props.defaultCompareToggleState
        : compareProducts.toggleState;

    const onIconToogleClick = e => {
      props.compareSet(true, ProductCategoryItem.COMPARE_TOGGLE_INPUT);
    };

    return (
      <div className="mx-1 item-compare-toggle" style={{ zIndex: 1 }}>
        {compareToggleState === ProductCategoryItem.COMPARE_TOGGLE_ICON ? (
          <>
            <Form.Check.Label
              onClick={onIconToogleClick}
              className="cursor-pointer"
            >
              {i18n.LABEL_COMPARE}
            </Form.Check.Label>
            <FontAwesomeIcon
              icon="exchange-alt"
              className="ml-1 cursor-pointer"
              id={"compare-item-" + productItem.id}
              onClick={onIconToogleClick}
            />
          </>
        ) : null}
        {compareToggleState === ProductCategoryItem.COMPARE_TOGGLE_INPUT ? (
          <>
            <Form.Check.Label for={"compare-item-" + productItem.id}>
              {i18n.LABEL_COMPARE}
            </Form.Check.Label>
            <Form.Check.Input
              id={"compare-item-" + productItem.id}
              type="checkbox"
              inline
              className="mx-1 item-compare-check position-relative"
              defaultChecked={comparedItem && comparedItem.enabled}
              checked={comparedItem && comparedItem.enabled}
              onChange={e =>
                handleCompareItemChange(e.currentTarget.checked, productItem.id)
              }
            />
          </>
        ) : null}
      </div>
    );
  };

  let compareItemToggle = null;

  const discount = getProductDiscount(props.item);

  const thumbnail =
    productItem.img || props.placeholder
      ? renderImageThumbnail(discount)
      : null;

  const isEnergyClass = item => "attribute_energy_class" === item.type;

  const energyClass = props.placeholder
    ? null
    : props.item.specs.items
        .filter(item => item.items.some(isEnergyClass))
        .reduce((carry, item) => {
          const result = item.items.filter(isEnergyClass)[0];

          return result.value[0];
        }, null);

  let itemIsCompared = null;

  if (props.comparable && compareProducts.enabled) {
    const comparedItem = compareProducts.items[props.id];
    itemIsCompared = Boolean(comparedItem && comparedItem.enabled);

    compareItemToggle = renderCompareItemToggle(itemIsCompared);
  }

  const [ref, inView] = useInView({
    triggerOnce: props.triggerOnce,
    threshold: props.threshold
  });

  useEffect(() => {
    if (inView) {
      props.pushProductImpression(
        props.item,
        props.selectorType + "/" + props.impressionList
      );
    }
  }, [props, inView]);

  const i18nFavorite = Object.keys(
    props.i18n.components.ProductCategoryFavorite
  ).reduce(
    (carry, key) =>
      Object.assign(carry, {
        [key]: props.i18n.components.ProductCategoryFavorite[key].replace(
          /%product%/g,
          productItem.title
        )
      }),
    {}
  );

  const favoriteSymbol =
    PRODUCT_SELECTOR_TYPE_FAVORITE === props.selectorType &&
    productItem.favorite.isFavorite ? (
      <React.Fragment>&times;</React.Fragment>
    ) : undefined;

  const favorite =
    props.supportsFavorites && !compareItemToggle ? (
      <ProductFavorite
        id={productItem.id}
        checked={productItem.favorite.isFavorite}
        onChange={e =>
          productItem.favorite.onChange(e, e.currentTarget.checked)
        }
        i18n={i18nFavorite}
        symbol={favoriteSymbol}
      />
    ) : null;

  return (
    <Card
      ref={ref}
      id={ProductCategoryItemBS + "-" + productItem.id}
      className={getClassName(
        null,
        [
          props.className,
          getSEOScoreLevel(productItem.seo),
          mediaImageClassName
        ]
          .filter(Boolean)
          .join(" ")
      )}
      style={{ width: props.width ? props.width : null }}
      data-id={productItem.id}
      data-seo-desc={getSEOScoreDesc(productItem.seo)}
      data-product-available={isProductAvailable(props)}
      data-product-discounted={Boolean(discount.percent)}
      data-product-compared={itemIsCompared}
    >
      {ribbons}
      {/* RATING */}
      <Container>
        <Row>
          <Col xs={12 - (favorite ? 2 : 0)} className="col-rating">
            <ProductRating
              rating={productItem.rating}
              className={getClassName("rating")}
              placeholder={props.placeholder}
            />
          </Col>

          {favorite ? (
            <Col xs={2} className="col-favorite">
              {favorite}
            </Col>
          ) : null}
        </Row>
      </Container>
      {/* TOP IMAGE */}
      {thumbnail}
      {/* TITLE */}
      <Card.Body className={getClassName("summary")}>
        {/* BRAND */}
        <div className="d-flex align-items-center justify-content-between w-100">
          <span className={getClassName("brand-title")}>
            {withPlaceholder(
              props.placeholder,
              productItem.brand ? productItem.brand.title : null || <br />
            )}
          </span>
          {compareItemToggle}
        </div>
        <ProductTitle
          as={props.titleAs || "h2"}
          title={productItem.title}
          subtitle={productItem.subtitle}
          subtitleAsList
          className={getClassName("title")}
          onClick={productItem.buttons.product.onClick}
          placeholder={props.placeholder}
          href={productItem.buttons.product.href}
          searchHint={{
            score: productItem._score,
            order: productItem._order,
            keyScore: productItem._keyScore
          }}
        />
      </Card.Body>
      <Container>
        {/* PRICE & ENERGY CLASS */}
        <Row>
          <Col xs={9} md={8}>
            <ProductPrice
              i18n={props.i18n.components.Product}
              oldPrice={productItem.oldPrice}
              newPrice={productItem.newPrice}
              currencyPrefix={productItem.currencyPrefix}
              currencySuffix={productItem.currencySuffix}
              currencyCode={productItem.currencyCode}
              inline
              placeholder={props.placeholder}
            />
          </Col>
          <Col xs={3} md={4}>
            <EnergyClass
              energyClass={energyClass}
              href={
                productItem.buttons.product.href +
                (productItem.energyLabelImg
                  ? "#" + productItem.energyLabelImg.src.replace(/\//g, "-")
                  : "")
              }
              placeholder={props.placeholder}
            />
          </Col>
        </Row>
        {/* AVAILABILITY & ENERGY CLASS */}
        <Row>
          <Col xs={9} md={8}>
            <Availability
              {...productItem.availability}
              placeholder={props.placeholder}
            />
          </Col>
          <Col xs={3} md={4}>
            <EnergyClass
              energyClass={energyClass}
              href={productItem.buttons.product.href + "#product-specification"}
              className="title"
              factory={_props => {
                return (
                  <span className={_props.className}>
                    {props.i18n.components.Product.PRODUCT_DATASHEET}
                  </span>
                );
              }}
              placeholder={props.placeholder}
            />
          </Col>
        </Row>
        {/* BUTTONS */}
        <Row>
          <Col className="px-3 pb-3">
            <ProductButtons
              {...productItem.availability}
              buttons={productItem.buttons}
              showProductButton={props.visibleButtons.product}
              showIcon={false}
              placeholder={props.placeholder}
            />
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

ProductCategoryItem.COMPARE_TOGGLE_INPUT = "input";
ProductCategoryItem.COMPARE_TOGGLE_ICON = "icon";

ProductCategoryItem.propTypes = {
  impressionList: PropTypes.string,
  selectorType: PropTypes.oneOf(PRODUCT_PAGE_SELECTORS),
  className: PropTypes.string,
  comparable: PropTypes.bool,
  compareProducts: PropTypes.object,
  defaultCompareToggleState: PropTypes.oneOf([
    ProductCategoryItem.COMPARE_TOGGLE_INPUT,
    ProductCategoryItem.COMPARE_TOGGLE_ICON
  ]),
  item: PropTypes.shape(ProductProps()),
  imgSize: PropTypes.shape(MaxSizeProps()),
  i18n: PropTypes.object,
  tagDiscount: PropTypes.bool,
  supportsTagDiscount: PropTypes.oneOf(["round", "rect", ""]),
  triggerOnce: PropTypes.bool,
  threshold: PropTypes.number,
  titleAs: JSXProps(),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  //
  compareItemAdd: PropTypes.func,
  compareItemRemove: PropTypes.func,
  pushProductImpression: PropTypes.func,
  visibleButtons: PropTypes.shape({
    buy: PropTypes.bool,
    preorder: PropTypes.bool,
    product: PropTypes.bool
  }),
  placeholder: PropTypes.bool
};

ProductCategoryItem.defaultProps = {
  visibleButtons: { buy: true, preorder: true, product: false },
  comparable: true,
  comparing: true,
  tagDiscount: true,
  triggerOnce: true,
  threshold: 0.5,
  defaultCompareToggleState: ProductCategoryItem.COMPARE_TOGGLE_INPUT
};

// ------------------- REDUX ----------------------

ProductCategoryItem.mapStateToProps = (state, ownProps) => {
  return {
    compareProducts: state.compareProducts,
    ribbons: state.siteRibbons.ribbons
  };
};

ProductCategoryItem.mapDispatchToProps = {
  compareSet,
  compareItemAdd,
  compareItemRemove,
  //
  pushProductImpression
};

ProductCategoryItem.mapValueToProps = value => ({
  supportsFavorites: value.supportsFavorites,
  supportsTagDiscount: value.supportsTagDiscount
});

export default connectHOCs(ProductCategoryItem, {
  withSite: true,
  withConnect: true,
  withGraphQL: true,
  withMemo: true
});
