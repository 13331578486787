import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { joinNonEmptyStrings } from "@utils/strings";
import LinkItem from "../Link/Item";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import Picture from "./Picture";
import { getClassname } from "./Placeholder";
import PureComponent from "./PureComponent";
import RouteLink from "./RouteLink";

function DivWrapper(props) {
  if (props.className) {
    return (
      <div className={"d-inline-block " + props.className}>
        {props.children}
      </div>
    );
  }
  return props.children;
}

function ImgWrapper(props) {
  let imgProps = { title: props.title, autoHeight: props.autoHeight };

  if (typeof props.img === "string") {
    imgProps.src = props.img;
  }
  if (typeof props.img === "object") {
    imgProps = { ...imgProps, ...props.img };
  }

  return (
    <DivWrapper className={props.className}>
      <RouteLink to={props.url} title={props.title}>
        <Picture {...imgProps} placeholder={props.placeholder} />
      </RouteLink>
    </DivWrapper>
  );
}

function IconWrapper(props) {
  return (
    <DivWrapper className={props.className}>
      <RouteLink to={props.url} title={props.title}>
        <FontAwesomeIcon
          size="lg"
          icon={props.icon}
          color={props.iconColor}
          title={props.title}
          className={props.placeholder ? getClassname(props) : undefined}
        />
        <span className="ml-1">{props.title}</span>
      </RouteLink>
    </DivWrapper>
  );
}

export default class RouteLinkImage extends PureComponent {
  getItems() {
    let items = this.props.items.map((item, key) => {
      if (item.items && item.items.length) {
        const colspan = Math.round(12 / item.items.length);
        const colspanMd = "col-md-" + colspan;
        const lastColspanMd =
          "col-md-" + (12 - (item.items.length - 1) * colspan);

        return item.items.map((el, index) => (
          <DivWrapper
            className={joinNonEmptyStrings(
              item.className,
              el.colspan ||
                (index === item.items.length - 1 ? lastColspanMd : colspanMd),
              " "
            )}
            key={index}
            placeholder={this.props.placeholder}
          >
            <LinkItem {...el} />
          </DivWrapper>
        ));
      } else if (item.img) {
        return (
          <ImgWrapper
            {...item}
            key={key}
            placeholder={this.props.placeholder}
          />
        );
      }

      return (
        <IconWrapper {...item} key={key} placeholder={this.props.placeholder} />
      );
    });

    // eventually apply some custom filter(s), if any
    this.props.filters.forEach(filter => {
      items = filter(items);
    });

    // finally, classify the items, when needed
    if (this.props.className) {
      return <div className={this.props.className}>{items}</div>;
    }

    return items;
  }

  render() {
    return this.getItems();
  }
}

RouteLinkImage.propTypes = {
  ...ItemsAwareProps(),
  className: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.func),
  placeholder: PropTypes.bool
};

RouteLinkImage.defaultProps = {
  items: [],
  className: null,
  filters: []
};
