import { connectHOCs } from "@components-utils";
import { PageFooterBS } from "@style-variables";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import FooterLinks from "./Links";

const PageFooter = ({ linkSection }) => {
  return (
    <footer
      className={getComponentClassName(PageFooterBS, null, "row bg-dark")}
    >
      <Container fluid>
        <Container className="inner">
          <FooterLinks {...linkSection} />
        </Container>
      </Container>
    </footer>
  );
};

PageFooter.propTypes = {
  linkSection: PropTypes.object,
  brandSection: PropTypes.object
};

PageFooter.mapValueToProps = value => ({
  linkSection: value.footer.linkSection,
  brandSection: value.footer.brandSection
});

export default connectHOCs(PageFooter, { withSite: true });
