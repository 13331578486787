import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import MaxSizeProps from "@prop-types/MaxSizeProps";
import { PictureBS } from "@style-variables";
import PictureHelper from "@utils/picture";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Image } from "react-bootstrap";
import LazyAwareComponent from "./LazyAwareComponent";
import { getClassname } from "./Placeholder";

// TODO: blur up effect
// https://css-tricks.com/the-blur-up-technique-for-loading-background-images/
//
// TruthSocial.com uses a canvas with negative z-index and a div with a background-image.
// The canvas shows the low-res/blured image, the div loads on background the real image.
// As the real image loading progresses it overlaps the blured image. Finally the real image
// sits on top the blured image.

/**
 * @description A picture component able to render a Cloudinary|normal multi-source lazy-loading picture/image
 * @export
 * @class Picture
 * @extends {LazyAwareComponent}
 * @see https://www.sitepoint.com/how-to-build-your-own-progressive-image-loader/
 * @see https://web.dev/preload-responsive-images/
 */
class Picture extends LazyAwareComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      loadError: false
    };

    // a picture helper instance
    this.helper = new PictureHelper();
  }

  getData() {
    this.helper.setProps({ ...this.props, lazyLoading: this.shouldLazyLoad() });
    return this.helper.getProps();
  }

  /**
   * @description Render the picture inner image element
   * @param {Object} [props={}] Extra properties to pass to the Image component
   * @returns {JSX}
   * @memberof Picture
   */
  renderDefaultImage(props = {}) {
    return (
      <Image
        {...props}
        onLoad={e => {
          this.setState({ loadError: false, fetched: true });

          if (this.props.autoHeight) {
            const { width, height } = e.currentTarget.getBoundingClientRect();

            if (!e.currentTarget.style.height) {
              e.currentTarget.style.height = height + "px";
            }
            if (!e.currentTarget.style.width) {
              e.currentTarget.style.width = width + "px";
            }
          }

          if (typeof this.props.onLoad === "function") {
            this.props.onLoad(e);
          }
        }}
        onError={e => {
          console.error(`Could not load the image ${props.src}`);

          this.setState({ loadError: true, fetched: true });

          if (typeof this.props.onLoad === "function") {
            this.props.onLoad(e);
          }
        }}
      />
    );
  }

  renderVideoSources() {
    const width = Math.max(window.screen.width, window.screen.height); //this.helper.getDefaultImageSize();

    const sources = [
      ["mp4", "hvc1"], // H.265/HEVC-encoded MP4, Safari only
      ["webm", "vp9"], // VP9, a codec supported by Edge, Firefox, and Chrome
      "mp4" // older browser fallback
    ].map((item, i, array) => {
      const [format, codecs] = "string" === typeof item ? [item] : item;

      const url = this.helper.getImageUrl(width, format);
      return (
        <source
          type={["video/" + format, codecs ? "codecs=" + codecs : null]
            .filter(Boolean)
            .join("; ")}
          src={url}
          key={i}
          onError={e => {
            if (i !== array.length - 1) {
              return;
            }

            const url = this.helper.getImageUrl(
              this.helper.getDefaultImageSize()
            );
            console.error(`Could not load the image ${url}`);

            this.setState({ loadError: true, fetched: true });

            if (typeof this.props.onLoad === "function") {
              this.props.onLoad(e);
            }
          }}
        />
      );
    });

    sources.push("Your browser does not support HTML5 video tag.");

    return sources;
  }

  /**
   * @description Render the picture as the given component
   * @param {function|Object} AsComponent - An alternative component (eg. <div>)
   * @returns {JSX}
   * @memberof Picture
   */
  renderAs(AsComponent) {
    return (
      <AsComponent
        {...this.helper.stripNonImageAttrs(this.props)}
        fluid={null}
        thumbnail={null}
      />
    );
  }

  renderAsVideo() {
    const className = getComponentClassName(
      PictureBS,
      null,
      this.props.className
    );

    const posterWidth = Math.max(window.screen.width, window.screen.height);
    const poster = this.helper.getImageUrl(posterWidth);

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        preload="metadata"
        className={className}
        controls={this.props.controls}
        loop={this.props.loop}
        // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        autoPlay={this.props.autoPlay}
        muted={false}
        width="100%"
        poster={poster}
        onLoadedData={e => {
          //e.currentTarget.play();
        }}
        onLoadedMetadata={e => {
          this.setState({ loadError: false, fetched: true });
          if (typeof this.props.onLoad === "function") {
            this.props.onLoad(e);
          }
        }}
      >
        {this.renderVideoSources()}
      </video>
    );
  }

  /**
   * @description Renders the placeholder image on image error/not loaded
   * @param {String} [className=null] Optional class for the image
   * @returns {FontAwesomeIcon}
   * @memberof Picture
   */
  renderErrorImage(className = null) {
    const icon = this.props.video
      ? this.props.errorVideo
      : this.props.errorImage;

    if (!icon) {
      return null;
    }

    const aspect =
      !this.props.aspect || 1 === this.props.aspect ? 0.618 : this.props.aspect;

    let dataSrc = null;
    if ("function" !== typeof this.props.src) {
      dataSrc = this.helper.getImageUrl(this.helper.getDefaultImageSize());
    }

    const style = {
      ...(this.props.style || {}),
      ...(this.getResponsiveStyle(aspect, this.props.sizes) || {})
    };

    if (this.props.sizes) {
      const sizes = Object.values(this.props.sizes).filter(Boolean);
      style.maxWidth = style.maxWidth || Math.max(...sizes);
      style.minWidth = style.minWidth || Math.min(...sizes);
      style.maxHeight = Math.round(style.maxWidth * aspect);
      style.minHeight = Math.round(style.minWidth * aspect);
    }

    style.maxHeight =
      style.height ||
      style.maxHeight ||
      (style.maxWidth
        ? style.maxWidth / (this.props.aspect || 1.618)
        : undefined) ||
      undefined;

    const styleKeys = style ? Object.keys(style).length : 0;

    const _className = [
      "mx-auto d-inline-block w-100",
      className,
      styleKeys ? null : "w-75 h-75",
      this.props.src && this.props.src.indexOf("/Error/") !== -1
        ? this.props.errorImageClassName
        : this.props.missingImageClassName
    ]
      .filter(Boolean)
      .join(" ");

    //const color = this.props.src ? "text-light" : "text-black-50";
    // `data-src` attribute is a trace of the real URL that failed loading
    return (
      <FontAwesomeIcon
        style={{ ...style }}
        icon={icon}
        className={_className}
        data-src={dataSrc}
      />
    );
  }

  /**
   * @description Render the placeholder image
   * @returns {JSX}
   * @memberof Picture
   */
  renderPlaceholderImage() {
    return this.renderErrorImage(getClassname());
  }

  /**
   * @description Render the picture as a multi-source image
   * @returns {JSX}
   * @memberof Picture
   */
  renderAsDefault() {
    // note: in case the browser cache is disabled the visible slide image is fetched again
    // a better version would manage its own cached Image collection and assign a pre-loaded Image to the visible slide

    const className = getComponentClassName(
      PictureBS,
      null,
      this.props.className
    );

    // the precomputed/cached picture rendering props
    const data = this.getData();

    if (this.props.placeholder) {
      return this.renderPlaceholderImage();
    }

    if (this.state.loadError || !this.props.src) {
      return this.renderErrorImage();
    }

    // probably an inline image (eg. SVG)
    if (typeof this.props.src === "function") {
      return <this.props.src className={className} />;
    }

    if (this.props.video) {
      return this.renderAsVideo();
    }

    const sources = data.sources
      ? data.sources.map((item, index) => (
          <source
            srcSet={item.srcSet}
            sizes={item.sizes}
            media={item.media}
            key={index}
          />
        ))
      : null;

    return (
      <picture className={className}>
        {sources}
        {this.props.ribbons}
        {this.renderDefaultImage({
          ...data.defaultImage,
          placeholder: null,
          extension: null
        })}
      </picture>
    );
  }

  render() {
    if (this.props.as) {
      return this.renderAs(this.props.as);
    }

    if (!this.state.fetched && this.shouldLazyLoad()) {
      if (typeof this.props.src !== "function") {
        return this.renderAsLazy();
      }
    }

    return this.renderAsDefault();
  }
}

Picture.propTypes = {
  ...ItemsAwareProps,
  ...LazyAwareComponent.propTypes,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  fluid: PropTypes.bool,
  padding: PropTypes.bool,
  imgSize: PropTypes.shape(MaxSizeProps),
  aspect: PropTypes.number,
  cloudinary: PropTypes.shape({
    cloudName: PropTypes.string.isRequired,
    path: PropTypes.string
  }),
  decoding: PropTypes.string,
  onLoad: PropTypes.func,
  version: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  video: PropTypes.bool,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  errorImage: PropTypes.string,
  errorVideo: PropTypes.string,
  errorImageClassName: PropTypes.string,
  placeholder: PropTypes.bool,
  ribbons: PropTypes.arrayOf(PropTypes.element),
  autoHeight: PropTypes.bool,
  removeBackground: PropTypes.bool
};

Picture.defaultProps = {
  ...LazyAwareComponent.defaultProps,
  fluid: true,
  padding: false,
  decoding: "async",
  errorImage: "image",
  errorVideo: "film",
  errorImageClassName: "text-warning",
  missingImageClassName: "text-light",
  version: false,
  video: false,
  autoPlay: false,
  controls: true,
  loop: true,
  autoHeight: true
};

export default React.memo(Picture);
