import PureComponent from "@components-core/PureComponent";
import { connectHOCs } from "@components-utils";
import { PAGE_KEY_LOGIN } from "@constants";
import ItemsAwareProps from "@prop-types/ItemsAwareProps";
import { getLoginStatus } from "@redux-utils";
import { LayoutBS } from "@style-variables";
import {
  mapValueToFeatures,
  mapValueToTemplates,
  mapValueToWidgets
} from "@templates/common/utils";
import { loadTemplate } from "@templates/utils";
import { isDevelopment } from "@utils/functions";
import { getComponentClassName } from "@utils/strings";
import PropTypes from "prop-types";
import React from "react";
import { Container } from "react-bootstrap";
import LayoutItem from "./Item";

/**
 * @description Helper component that redirects a protected page to login page
 * @class _ProtectedPageRedirect
 * @extends {PureComponent}
 */
const ProtectedPageRedirect = connectHOCs(
  class extends PureComponent {
    constructor(props) {
      super(props);

      this.doRedirect();
    }

    static mapStateToProps = state => {
      const currentstate = state.userLogin;
      const loginStatus = getLoginStatus(currentstate);

      return { redirect: !(loginStatus.isLogged && loginStatus.isUser) };
    };

    doRedirect() {
      if (this.props.redirect) {
        const redirectFrom = this.props.pathfinder.applySearchParams(
          window.location.pathname
        );
        const redirectTo = this.props.pathfinder.generate(PAGE_KEY_LOGIN);

        if (redirectFrom !== redirectTo) {
          const redirectState = {
            redirectAfterLogin: redirectFrom
          };

          this.props.history.push(redirectTo, redirectState);
        }
      }
    }

    componentDidMount() {
      this.doRedirect();
    }

    render() {
      return null;
    }
  },
  {
    withConnect: true,
    withRouter: true
  }
);

class Layout extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { redirector: null };
  }

  componentDidMount() {
    this.setState({ redirector: this.getRedirector() });
  }

  /**
   * @description Get the contextual `undefined` valueProps that is passed to the template function arguments
   * @param {String} templateName The template function name
   * @returns {String}
   * @memberof Layout
   */
  __getUndefinedValueProps(templateName) {
    return {
      "Layout.js says": `use ${
        templateName ? templateName : "YourExportedFunctionName"
      }.mapValueToProps = value => ({ yourProp: value.grabSomeProp})`
    };
  }

  getRedirector() {
    if ((this.props.features || {}).protected) {
      return {
        as: ProtectedPageRedirect,
        props: {
          pathfinder: this.props.pathfinder
        }
      };
    }

    return null;
  }

  getItems() {
    const result = [];

    // inject a dummy component that enforces protected pages redirect to /login
    if (this.state.redirector) {
      result.push(this.state.redirector);
    }

    const envDev = isDevelopment();

    this.props.items.forEach((template, index) => {
      if ("string" === typeof template) {
        template = loadTemplate(template);
      }

      if (typeof template === "function") {
        let valueProps = {};

        const { pageKey, templateKey } = template.mapValueToTemplateProps || {};

        const passthroughValue = {
          ...this.props.siteContext,
          router: {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match
          }
        };

        if ("function" === typeof template.mapValueToProps) {
          valueProps = template.mapValueToProps(passthroughValue);
        } else if (envDev) {
          valueProps = this.__getUndefinedValueProps(template.name);
        }

        if (pageKey) {
          valueProps.features = mapValueToFeatures(passthroughValue, pageKey);
          valueProps.widgets = mapValueToWidgets(passthroughValue, pageKey);
        }
        if (templateKey) {
          valueProps.templates = mapValueToTemplates(
            passthroughValue,
            templateKey
          );
        }

        result.push(
          ...template({
            ...valueProps,
            templates: valueProps.templates.filter(item => item.component),
            role: this.props.role
          })
        );
      } else {
        result.push(template);
      }
    });

    return result;
  }

  render() {
    const items = this.getItems().map((item, index) => {
      return <LayoutItem key={index} {...item} />;
    });

    return (
      <Container
        fluid
        className={getComponentClassName(
          LayoutBS,
          "items",
          this.props.className
        )}
        style={this.props.style}
      >
        {items}
      </Container>
    );
  }
}

Layout.propTypes = {
  ...ItemsAwareProps,
  className: PropTypes.string,
  style: PropTypes.object
};

Layout.mapValueToProps = value => ({
  siteContext: value
});

export default connectHOCs(Layout, { withSite: true, withRouter: true });
